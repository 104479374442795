<template>
  <div>

    <div class="table-responsive table-full-width se-findings-table mt-1" >
          <el-table
            ref="collectedDataTable"
            :data="this.tableData"
            row-key="id"
            :border="false"
            header-row-class-name="se-findings-table-header"
            :header-cell-class-name="headerCellClassName"
            row-class-name="se-findings-table-row"
            :cell-class-name="cellClassName">

            <el-table-column
              class-name="se-findings-item-column"
              :min-width="150">
              <template slot-scope="scope">
                {{scope.row.name}}
                <a class="se-help-icon btn-link" v-tooltip.top-center="'Learn More'" @click="handleFieldHelp(scope.row.id)" v-show="scope.row.hasHelp">
                  <i class="fa fa-info-circle"></i>
                </a>
              </template>
            </el-table-column>

            <el-table-column
              header-align="center"
              label="Tax Returns">
              <el-table-column
                :label="getPY1"
                header-align="center"
                align="right"
                :width="185"
                :min-width="40">
                <template slot-scope="scope">
                  <div class="row" v-if="scope.row.id !== 'total'">
                    <div class="col-8">{{ scope.row.currentYearTaxValue | toCurrency(scope.row.isCurrency) }}<span v-if="scope.row.currentYearTaxValue2"><br>{{ scope.row.currentYearTaxValue2 }}</span></div>
                    <div v-if="scope.row.currentYearFieldLocation">
                      <a href="#" @click="editField(scope.row.currentYearFieldLocation)">
                      <icon-base width="22" height="22" iconColor="rgba(0,0,0,0.80)" icon-name="Edit"><icon-edit/></icon-base>
                      </a>
                    </div>
                  </div>
              </template>
              </el-table-column>
            </el-table-column>

            <el-table-column
              align="right"
              :width="20"
              :min-width="20">
            </el-table-column>

            <el-table-column
              header-align="center"
              :label="appName">
              <el-table-column
                :label="getPY1"
                label-class-name="se-findings-table-eval-result-header"
                class-name="se-eval-result"
                align="right"
                header-align="center"
                :width="185"
                :min-width="40">
                <template slot-scope="scope">
                  <div class="col-9">
                    <span :class="{'font-weight-bold': scope.row.id === 'total' }" >
                      <template v-if="scope.row.currentYearSeValueMemo"><a class="se-help-a" v-tooltip.top-center="'Learn More'" @click="showSeMemo(scope.row.id, scope.row.currentYearSeValue, scope.row.cy, scope.row.currentYearSeValueMemo)">{{ scope.row.currentYearSeValue | toCurrency(scope.row.isCurrency) }}</a></template>
                      <template v-else>{{ scope.row.currentYearSeValue | toCurrency(scope.row.isCurrency) }}</template>
                    </span>
                  </div>
                </template>
              </el-table-column>
            </el-table-column>

          </el-table>
        </div>

    <se-help :visible.sync="showFieldHelp.fairRentalDaysCount">
      <div class="se-field-help">
        <p>
          Form 1040, Schedule E, Line 2.<br>
		  The Fair Rental Days are used to determine the property's number of months in service, which is used for income averaging.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.rentsReceived">
      <div class="se-field-help">
        <p>
          Form 1040, Schedule E, Line 3.<br>
		  Any amount on this line is added to the rental income.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.rentalPropertyAccessoryBoarderIncome">
      <div class="se-field-help">
        <p>
          The user-provided amount of Accessory Dwelling Unit or Boarder income that was included in Rents Received on Form 1040, Schedule E, Line 3.<br>
		  Any amount on this line is subtracted from the rental income.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.rentalPropertyTotalExpenses">
      <div class="se-field-help">
        <p>
          Form 1040, Schedule E, Line 20.<br>
		  Any amount on this line is subtracted from the rental income.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.rentalPropertyInsurance">
      <div class="se-field-help">
        <p>
          Form 1040, Schedule E, Line 9.<br>
		  Any amount on this line is added to the rental income.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.rentalPropertyMortgageInterestPaidToBanks">
      <div class="se-field-help">
        <p>
          Form 1040, Schedule E, Line 12.<br>
		  Any amount on this line is added to the rental income.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.rentalPropertyOtherDeductibleMortgageInterest">
      <div class="se-field-help">
        <p>
          The user-provided amount of Other Deductible Mortgage Interest that was included in Other Interest on Form 1040, Schedule E, Line 13.<br>
		  Any amount on this line is added to the rental income.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.rentalPropertyTax">
      <div class="se-field-help">
        <p>
          Form 1040, Schedule E, Line 16.<br>
		  Any amount on this line is added to the rental income.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.rentalPropertyHomeownersAssociationDues">
      <div class="se-field-help">
        <p>
          The user-provided amount of Homeowners Association/Condo/Coop Dues on the tax returns. <br>
		  Any amount on this line is added to the rental income.
        </p>
        <P>
          <B>FAQs:</B><br>
          Q. The rental property is a condominium, cooperative or planned unit development (PUD). There is no amount on the tax return specified to be for 
          homeowners association dues. Can I enter the Cleaning and maintenance fees from Line 7 or the Management fees from Line 11 as the homeowners association dues?<br>
		  A. No. The homeowners association dues must be specifically identified in the tax returns, either on Schedule E or on an attachment, or the lender must obtain 
          documentation, such as written confirmation from the tax preparer to validate the homeowners association dues that were included as an a rental property expense.
        </P>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.rentalPropertyIndividualPropertyDepreciation">
      <div class="se-field-help">
        <p>
          Form 1040, Schedule E, Line 18.<br>
		  Any amount on this line is added to the rental income.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.casualtyTheftLoss">
      <div class="se-field-help">
        <p>
          Form 4684, Lines 31, 32, 36 and 37. <br>
          {{ appName }} determines if there are gains or losses from any casualties or thefts.
          Any loss amounts are added to the rental income. If the borrower owns more than one business,
          ensure the amounts provided relate to the business being evaluated by {{ appName }}.
        </p>
        <p>
          <B>FAQs:</B><br>
          Q. Why is the pencil icon grayed-out (not able to be selected)?<br>
          A. {{ appName }} will display Form 4684 when it is identified as being included
          in the tax returns on the Losses and Expenses screen.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.extraordinaryOneTimeExpenses">
      <div class="se-field-help">
        <p>
          Any amount identified by the lender as an extraordinary one-time expense is added back to the rental income.
        </p>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
          Learn more about Analyzing Individual Tax Returns (and extraordinary one-time expenses) in the <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.3-01/general-information-analyzing-individual-tax-returns">General Information on Analyzing Individual Tax Returns</a> section of the Fannie Mae Selling Guide.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.total">
      <div class="se-field-help">
        <p>
          The annual rent amount minus the total expenses, and subtracting accessory dwelling unit income and boarder income when applicable, adding amounts for 
          depreciation/depletion, principal, interest, taxes, insurance and homeowners association dues (PITIA), carryover losses and extraordinary one-time expenses.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showHelpMessageMemo" title="Learn More">
      <p>
        {{showHelpMessageMemoText}}
      </p>
      <p v-if="showHelpMessageLearnMoreUrl">
        <a target="_blank" :href="showHelpMessageLearnMoreUrl">Learn more in the Fannie Mae Selling Guide</a>
      </p>
    </se-help>
  </div>

</template>
<script>
import {Radio, IconEdit, IconCheckMark} from 'src/components/index'
import LSwitch from 'src/components/Switch.vue'
import SeButtons from './SeButtons.vue'
import Enums from 'src/model/enums'
import SeHelp from './SeHelp.vue'
import {Collapse, CollapseItem, Drawer, Table, TableColumn} from "element-ui"
import {FindingsMixin} from "src/mixins/FindingsMixin";
import appNameMixin from '../../../mixins/AppNameMixin'

export default {
  mixins: [FindingsMixin, appNameMixin],
  components: {
    Radio,
    LSwitch,
    SeButtons,
    SeHelp,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Drawer.name]: Drawer,
    IconEdit,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    IconCheckMark
  },
  data() {
    return {
      tableData: [],
      tableDataCasualtyTheftLoss: []
    }
  },
  mounted() {
    let currTaxFormFields1040se = this.getTaxFormFields(this.getPY1, Enums.SeTaxFormType.tf1040se);
    let currTaxFormFieldsLossExp = this.getTaxFormFields(this.getPY1, Enums.SeTaxFormType.tfLossesExpenses);
    let currTaxFormFields4684 = this.getTaxFormFields(this.getPY1, Enums.SeTaxFormType.tf4684);

    //casualty theft loss
    Object.entries(Enums.SeFindingsCasualtyTheftLossLineType).forEach( ([k,v]) => {
      let row = {};
      row['id'] = k;
      row['name'] = v.name;

      row.currentYearTaxValue = this.getTaxFormValue(currTaxFormFields4684, k);
      row.currentYearFieldLocation = this.getTaxForm1040SchEFieldLocation(this.getPY1, Enums.SeTaxFormType.tf4684, currTaxFormFields4684, k);

      row.isCurrency = true;
      row.seValueChangePct = 0
      row.currentYearSeValue = ''

      this.tableDataCasualtyTheftLoss.push(row)
    });

    //main entries
    Object.entries(Enums.SeFindingsSummaryRentalSchELineType).forEach( ([k,v]) => {
      let row = {}
      row['id'] = k
      row['name'] = v.name
      row.memoCy = v.memoCy
      row.responseFieldId = v.responseFieldId

      if (k === Enums.SeFindingsSummaryRentalSchELineType.extraordinaryOneTimeExpenses.id) {
        row.currentYearTaxValue = this.getTaxFormValue(currTaxFormFieldsLossExp, k);
        row.currentYearFieldLocation = this.getTaxForm1040SchEFieldLocation(this.getPY1, Enums.SeTaxFormType.tfLossesExpenses, currTaxFormFieldsLossExp, k);

      } else if (k === Enums.SeFindingsSummaryRentalSchELineType.casualtyTheftLoss.id) {
        row.currentYearTaxValue = this.getCasualtyTheftLossTotal(currTaxFormFields4684)
        row.children = this.tableDataCasualtyTheftLoss

      } else {
        row.currentYearTaxValue = this.getTaxFormValue(currTaxFormFields1040se, k);
        row.currentYearFieldLocation = this.getTaxForm1040SchEFieldLocation(this.getPY1, Enums.SeTaxFormType.tf1040se, currTaxFormFields1040se, k);
      }

      row.isCurrency = ![Enums.SeFindingsSummaryRentalSchELineType.fairRentalDaysCount.id].includes(k)
      row.seValueChangePct = 0
      row.currentYearSeValue = ''

      row.hasHelp = true
      this.$set(this.showFieldHelp, k, false)

      this.tableData.push(row)
    });

    this.$set(this.showFieldHelp, 'total', false)
    this.tableData.push({id: 'total', name: 'Adjusted Rental Income', currentYearSeValue:'', isCurrency:true, hasHelp:true});

  },
  computed: {
  },
  methods: {
    handleEvalResults() {
      let currResponseFields = this.seResponse.taxYears?.find(ty => ty.year === this.getPY1) ?? {}

      this.tableData.forEach(row => {
        row.cy = this.getPY1

        if (row.id === 'total') {
          row.currentYearSeValue = currResponseFields.adjustedRentalIncome || 0
          row.memoCy = Enums.SeAdjustedRentalIncomeMemos
          row.currentYearSeValueMemo = this.findFirstMessageId(row.memoCy)

        } else {
          let responseFieldId = row.responseFieldId ?? row.id
          row.currentYearSeValue = currResponseFields[responseFieldId] || 0;
          row.currentYearSeValueMemo = row.currentYearTaxValue != row.currentYearSeValue ? this.findFirstMessageId(row.memoCy) : false
        }
      });
    },
    getTaxForm1040SchEFieldLocation(taxYear, taxFormType, fields, fieldId) {
      return this.getTaxFormFieldLocation(taxYear, Enums.SeTaxFormType.tf1040se, taxFormType, fields, fieldId)
    },
    getCasualtyTheftLossTotal(fields) {
      let total = this.getTaxFormValueAsNumber(fields, "casualtyTheftNonIncomePropertyShortTermGainLossAmount")
        - this.getTaxFormValueAsNumber(fields, "casualtyTheftIncomePropertyShortTermLossAmount")
        + this.getTaxFormValueAsNumber(fields, "casualtyTheftLongTermGainAmount")
        - this.getTaxFormValueAsNumber(fields, "casualtyTheftLongTermLossAmount")
      return total < 0 ? -Math.abs(total) : 0.0
    },
    cellClassName({row, column, rowIndex, columnIndex}) {
      let className = ''
      if (columnIndex === 1 || (this.getNumberOfSelectedTaxFormYears > 1 && columnIndex === 2)) {
        className+=' se-findings-tax-return-columns';
      }
      if (columnIndex === 3) {
        className+=' cell-no-border'
      }
      if (row.id === 'total') {
        className+= 'font-weight-bold'
      }
      return className;
    },
    headerCellClassName({row, column, rowIndex, columnIndex}) {
      let className = ''
      if (rowIndex === 0 && columnIndex === 0) {
        className+='se-findings-table-items-header';
      }
      if (rowIndex === 0 && columnIndex === 2) {
        className+=' se-findings-table-sign-header';
      }
      if (rowIndex === 0 && columnIndex === 3) {
        className+=' se-findings-table-eval-result-header';
      }
      if (rowIndex === 0 && columnIndex === 4) {
        className+=' se-findings-table-change-header';
      }
      return className;
    },
    showSeMemo(id, value, year, msgId) {
      if (msgId) this.displayMessageMemo(msgId)
    }

  }

}
</script>
<style>


.se-findings-table > div {
  border: 0;
  /*margin-right: 50px;*/
}

.se-findings-table .el-table:before {
  height: 0 !important;
}
.se-findings-table .el-table:after {
  width: 0 !important;
}

.el-table__row--level-1 .se-findings-item-column > div{
  text-indent: -36px;
  padding-left: 36px !important;
  word-break: break-word;
}

.se-findings-table-header {
  font-size: 1.1rem;
  color: rgba(0,0,0,0.75) ;
}

.se-findings-table-header > th {
  border-right: 0;
}

.se-findings-table-row > td {
  border-right: 0;
}

.se-findings-item-column {
  padding-left: 0 !important;
  font-size: 1.0rem;
}

.se-findings-item-column-total {
  padding-left: 0 !important;
  font-size: 1.0rem;
  font-weight: bold;
}

.cell-no-border {
  border-top: 0 !important;
  border-bottom: 0
}

.se-findings-tax-return-columns {
  background-color: #F5F7FA !important;
  font-size: 1.0rem;
}

.se-findings-table-items-header {
  background-color: #fff !important;
}

.se-findings-table-change-header {
  background-color: #fff !important;
  border-top: 0 !important;
  border-right: 0 !important;
}

.se-findings-table-sign-header {
  background-color: #fff !important;
  border-top: 0 !important;
  border-bottom: 0 !important;
}

.se-findings-table-eval-result-header {
  background-color: rgba(229,248,255,1.0) !important;
  /*background-color: rgba(35, 129, 150, 0.2) !important;*/
  font-weight: 700 !important;
  font-size: 1.1rem !important;
  color: rgba(0,0,0,0.75) !important;
}

.se-eval-result {
  background-color: rgba(229,248,255,1.0) !important;
  /*background-color: rgba(35, 129, 150, 0.2) !important;*/
  font-weight: 500;
  font-size: 1rem;
  color: black;
}

.se-eval-result-change {
  font-size: 1rem;
  color: black;
}

</style>
